<template>
  <div>
    <v-img
        src="../assets/images/background_login.jpeg"
        style="background-size: cover"
        gradient="to bottom right, white 10%, transparent 90%"
    >
      <v-row>
        <v-col cols="12" sm="7" md="7">
        </v-col>
        <v-col cols="12" sm="5" md="5">
          <v-flex class="mt-10 mb-2 mr-4">
            <v-card elevation="0"
                    class="mx-auto"
                    max-width="500"
                    color="rgb(255, 0, 0, 0.05)"
            >
              <v-card-title class="white--text">
                <v-spacer/>
                LOGIN
                <v-spacer/>
              </v-card-title>
              <v-card-text>
                <v-text-field
                    v-model="user.username"
                    label="Nomor HP (username)"
                    hide-details="auto"
                ></v-text-field>
                <v-text-field
                    v-model="user.password"
                    :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show1 ? 'text' : 'password'"
                    label="Password"
                    hide-details="auto"
                    @click:append="show1 = !show1"

                ></v-text-field>
              </v-card-text>
              <v-card-actions class="ma-4">
                <v-spacer/>
                <v-btn
                    color="primary"
                    @click="handleLogin"
                    width="200px"
                >Login</v-btn>
                <v-spacer/>
              </v-card-actions>
            </v-card>
          </v-flex>
        </v-col>

      </v-row>

    </v-img>
  </div>

</template>

<script>
import User from '../models/user';

export default {
  name: 'Login',
  components: {},
  data() {
    return {
      show1: false,
      user: new User('', ''),
      loading: false,
      message: ''
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    }
  },
  created() {
    if (this.loggedIn) {
      this.$router.push('/profile');
    }
  },
  methods: {
    handleLogin() {
      this.loading = true;
      this.$validator.validateAll().then(isValid => {
        if (!isValid) {
          this.loading = false;
          return;
        }

        if (this.user.username && this.user.password) {
          this.$store.dispatch('auth/login', this.user).then(
              () => {
                this.$router.push('/dashboard');
              },
              error => {
                this.loading = false;
                this.message =
                    (error.response && error.response.data && error.response.data.message) ||
                    error.message ||
                    error.toString();
              }
          );
        }

      });
    }
  }
};
</script>
<style scoped>

</style>


